import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { Eur1CounterListResponse, Eur1CounterResponse } from 'Types/admin/admin'

import { getAccessToken, getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get list of EUR-1 counters
 */
export const getEur1CounterList = createAsyncThunk(
  'admin/getEur1CounterList',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.EUR1_COUNTER.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<Eur1CounterListResponse> = await axios.get(url, config)
      return {
        eur1CounterList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getReusableEur1List = createAsyncThunk(
  'admin/getReusableEur1CounterList',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const url = `${ADMIN_API_URL.EUR1_COUNTER.ROOT}/reusable?page=${pageIndex}&limit=${pageSize}`

    try {
      const { data }: AxiosResponse<Eur1CounterListResponse> = await axios.get(url, getAxiosConfig())
      return {
        reusableEur1CounterList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Create EUR-1 counter
 */
export const createEur1Counter = createAsyncThunk(
  'admin/createEur1Counter',
  async (options: { from: number; to: number; prefix: string }) => {
    const token = getAccessToken()
    const url = ADMIN_API_URL.EUR1_COUNTER.ROOT
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<Eur1CounterResponse> = await axios.post(url, options, config)
      return {
        eur1Counter: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove EUR-1 counter
 */
export const removeEur1Counter = createAsyncThunk('admin/removeEur1Counter', async (id: string) => {
  const token = getAccessToken()
  const url = `${ADMIN_API_URL.EUR1_COUNTER.ROOT}/${id}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: boolean }> = await axios.delete(url, config)
    return {
      id,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
