import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Eur1CounterType } from 'Types/admin/admin'
import { PaginationProps } from 'Types/global.types.ts'

import { getReusableEur1List } from 'Api/admin/admin.eur1Counter.service.ts'

interface InitialStateProps {
  reusableEur1List: Eur1CounterType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  reusableEur1List: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
}

const adminReusableEur1ListSlice = createSlice({
  name: 'adminReusableEur1ListSlice',
  initialState,
  reducers: {
    setReusableEur1PageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReusableEur1List.fulfilled, (state, action) => {
        if (action.payload) {
          state.reusableEur1List = action.payload.reusableEur1CounterList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getReusableEur1List.pending, (state) => {
        state.loading = true
      })
      .addCase(getReusableEur1List.rejected, (state) => {
        state.loading = false
      })
  },
})

const { actions } = adminReusableEur1ListSlice

export const { setReusableEur1PageIndex } = actions

export default adminReusableEur1ListSlice.reducer
