import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FormikValues } from 'formik'

import { CertificateResponseType, CertificateType } from 'Types/global.types.ts'
import { CERTIFICATE_API, CERTIFICATE_BASE_API_URL, DOWNLOAD_API } from 'Constants/Global.constants.ts'
import { getAxiosBlobConfig, getAxiosConfig, getAxiosMultipartFormConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Create a new certificate
 */
export const createCertificate = createAsyncThunk('certificates/createCertificate', async (options: FormikValues) => {
  try {
    const { data }: AxiosResponse<CertificateResponseType> = await axios.post(
      CERTIFICATE_BASE_API_URL,
      options,
      getAxiosMultipartFormConfig()
    )
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Search certificates by:
 * - vin, registration (non-forwarder)
 * - buyer, company name (additional for forwarder)
 */
export const searchCertificates = createAsyncThunk(
  'certificates/search',
  async ({ param, pageIndex, pageSize }: { param: string; pageIndex: number; pageSize: number }) => {
    const url = `${CERTIFICATE_API.CERTIFICATE_SEARCH}?page=${pageIndex}&limit=${pageSize}&param=${param}`

    try {
      const { data }: AxiosResponse<{ data: { data: CertificateType[]; total: number } }> = await axios.get(
        url,
        getAxiosConfig()
      )
      return {
        certificates: data.data.data,
        total: data.data.total,
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.message)
      } else {
        throw new Error('An unexpected error occurred')
      }
    }
  }
)

export const getFile = (filePath: string) =>
  new Promise((resolve, reject) => {
    const url = `${DOWNLOAD_API.GET_FILE}${filePath}`

    axios
      // @ts-ignore
      .get(url, getAxiosBlobConfig())
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
