import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { reverse as _reverse } from 'lodash'

import { OrderType } from 'Definitions/Order.type.ts'
import { WorkStatusType } from 'Types/global.types.ts'
import {
  AdminEur1IdPayloadProps,
  AdminPrintDatePayloadProps,
  AdminVehicleType,
  CommentListResponse,
  CommentResponse,
  CustomFilesResponse,
  CustomFileType,
  InvoiceResponseType,
  OrderGeneralStatisticsResponse,
  OrderMonthlyStatisticsResponse,
  OrderParamsPayloadProps,
} from 'Types/admin/admin'

import { ADMIN_API_ROOT, ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAxiosBlobConfig, getAxiosConfig, getAxiosMultipartFormConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

interface OrderAssignAdminProps {
  orderId: string
  adminId: string
}

/**
 * Assign admin user to order
 */
export const assignAdminToOrder = createAsyncThunk(
  'admin/assignAdminOrder',
  async ({ adminId, orderId }: OrderAssignAdminProps) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}/assignee`

    const body = {
      adminId,
    }

    try {
      const { data }: AxiosResponse<{ data: OrderType; total: number }> = await axios.patch(url, body, getAxiosConfig())
      return {
        adminOrder: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Assign reviewer to order
 */
export const assignReviewerToOrder = createAsyncThunk(
  'admin/assignOrderReviewer',
  async ({ adminId, orderId }: OrderAssignAdminProps) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}/reviewer`

    const body = {
      adminId,
    }

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, body, getAxiosConfig())
      return {
        adminOrder: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove order
 */
export const removeAdminOrder = createAsyncThunk('admin/removeOrder', async (orderId: string) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}`

  try {
    const { data }: AxiosResponse<{ data: { deleted: boolean } }> = await axios.delete(url, getAxiosConfig())
    return {
      orderId: orderId,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Get list of users orders
 */
export const getOrderListByUser = createAsyncThunk(
  'admin/getOrderListByUser',
  async ({ param, userId }: { param: string; userId: string }) => {
    let url = `${ADMIN_API_URL.ORDER.ROOT}/search`
    const queryParams = []

    if (userId) {
      queryParams.push(`userId=${encodeURIComponent(userId)}`)
    }

    if (param) {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    try {
      const { data }: AxiosResponse<{ data: OrderType[]; total: number }> = await axios.get(url, getAxiosConfig())
      return {
        orderList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get list of all orders
 */
export const getOrderList = createAsyncThunk(
  'admin/getOrderList',
  async ({ param, sort, order, pageIndex, pageSize, customsStatus }: OrderParamsPayloadProps) => {
    let url = ADMIN_API_URL.ORDER.ROOT
    const queryParams = []

    if (param) {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (customsStatus) {
      queryParams.push(`status=${encodeURIComponent(customsStatus)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    try {
      const { data }: AxiosResponse<{ data: OrderType[]; total: number }> = await axios.get(url, getAxiosConfig())
      return {
        orderList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get order monthly statistics
 */
export const getOrderMonthlyStatistics = createAsyncThunk('admin/getOrderMonthlyStatistics', async () => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/monthly-statistics`

  try {
    const { data }: AxiosResponse<OrderMonthlyStatisticsResponse> = await axios.get(url, getAxiosConfig())
    return {
      monthlyStatistics: data.data.monthly,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Get order general statistics
 */
export const getOrderGeneralStatistics = createAsyncThunk('admin/getOrderGeneralStatistics', async () => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/general-statistics`

  try {
    const { data }: AxiosResponse<OrderGeneralStatisticsResponse> = await axios.get(url, getAxiosConfig())
    return {
      generalStatistics: data.data.number,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Get admin order details
 */
export const getAdminOrderDetails = createAsyncThunk('admin/getOrderDetails', async (orderId: string) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}`

  try {
    const { data }: AxiosResponse<{ data: OrderType }> = await axios.get(url, getAxiosConfig())
    return {
      orderDetails: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Update Order's vehicle data
 */
export const updateOrderDetails = createAsyncThunk(
  'admin/updateOrderDetails',
  async ({ certificateId, options }: { certificateId: string; options: AdminVehicleType }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        certificate: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Send Order email
 * @param certificateId
 * @param options
 */
export const sendOrderEmail = async ({
  certificateId,
  options,
}: {
  certificateId: string
  options: { email: string }
}) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/send-email`

  try {
    const { data }: AxiosResponse<{ data: boolean }> = await axios.post(url, options, getAxiosConfig())
    return {
      sent: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Get list of Order's comments
 */
export const getOrderComments = createAsyncThunk(
  'admin/getOrderComments',
  async ({ certificateId, pageIndex, pageSize }: { certificateId: string; pageIndex: number; pageSize: number }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/comment?page=${pageIndex}&limit=${pageSize}&order=DESC`

    try {
      const { data }: AxiosResponse<CommentListResponse> = await axios.get(url, getAxiosConfig())
      const reverseData = _reverse(data.data)
      return {
        comments: reverseData,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Post Order comment
 */
export const postOrderComment = createAsyncThunk(
  'admin/postOrderComment',
  async ({ certificateId, options }: { certificateId: string; options: { content: string } }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/comment`

    try {
      const { data }: AxiosResponse<CommentResponse> = await axios.post(url, options, getAxiosConfig())
      return {
        comment: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const removeOrderComment = createAsyncThunk('admin/removeOrderComment', async (commentId: string) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/comment/${commentId}`

  try {
    const { data }: AxiosResponse<CommentResponse> = await axios.delete(url, getAxiosConfig())
    return {
      comment: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Upload EO EUR-1 document
 */
export const uploadEoEur1Document = createAsyncThunk(
  'admin/uploadEoEur1',
  async ({ certificateId, options }: { certificateId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/eo-eur1`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(
        url,
        options,
        getAxiosMultipartFormConfig()
      )
      return {
        certificate: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload COO document
 * @path param: certificateId: string
 * @body param: coo: file
 */
export const uploadOrderCooDocument = createAsyncThunk(
  'admin/uploadOrderCooDocument',
  async ({ certificateId, options }: { certificateId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/coo`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(
        url,
        options,
        getAxiosMultipartFormConfig()
      )
      return {
        certificate: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload Order's car document
 */
export const uploadOrderCarDocument = createAsyncThunk(
  'admin/uploadOrderCarDocument',
  async ({ certificateId, options }: { certificateId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/car-documents`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(
        url,
        options,
        getAxiosMultipartFormConfig()
      )
      return {
        certificate: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get list of Order's custom files
 */
export const getOrderCustomFiles = createAsyncThunk(
  'admin/getOrderCustomFiles',
  async ({ certificateId, group }: { certificateId: string; group: string }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/custom-file?group=${group}`

    try {
      const { data }: AxiosResponse<CustomFilesResponse> = await axios.get(url, getAxiosConfig())
      return {
        customFiles: data.data,
        group,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Upload Order's custom file
 */
export const uploadOrderCustomFile = createAsyncThunk(
  'admin/uploadCustomFile',
  async ({ certificateId, options }: { certificateId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/custom-file`

    try {
      const { data }: AxiosResponse<{ data: CustomFileType }> = await axios.post(
        url,
        options,
        getAxiosMultipartFormConfig()
      )
      return {
        customFile: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update Order's payment state
 */
export const updateOrderPaymentState = createAsyncThunk(
  'admin/updateOrderPaymentState',
  async ({
    options,
    certificateId,
  }: {
    options: { paymentState: 'PAID' | 'NOT_PAID' | 'FAILED' | 'CANCELED' }
    certificateId: string
  }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/payment-state`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update Order's working state
 */
export const updateOrderState = createAsyncThunk(
  'admin/updateOrderState',
  async ({ certificateId, options }: { options: { state: WorkStatusType }; certificateId: string }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/state`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        certificate: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update Order EUR-1 id
 */
export const updateOrderEur1Id = createAsyncThunk(
  'admin/updateOrderEur1Id',
  async ({ options, certificateId }: { certificateId: string; options: AdminEur1IdPayloadProps }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/eur1`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update Order print date
 */
export const updateOrderPrintDate = createAsyncThunk(
  'admin/updateOrderPrintDate',
  async ({ options, certificateId }: { certificateId: string; options: AdminPrintDatePayloadProps }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/print-date`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getOrderInvoice = async (certificateId: string) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/invoice`

  try {
    const { data }: AxiosResponse<InvoiceResponseType> = await axios.get(url, getAxiosConfig())
    return {
      certificateInvoice: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

interface IOrderPaymentInformation {
  userId: string
  minDate: string
  maxDate: string
}

export const exportOrderPaymentInformation = async ({ userId, minDate, maxDate }: IOrderPaymentInformation) => {
  const url = `${ADMIN_API_ROOT}/export/certificate/${userId}?minDate=${minDate}&maxDate=${maxDate}`

  try {
    // @ts-ignore
    const data: AxiosResponse = await axios.get(url, getAxiosBlobConfig())
    return data
  } catch (error) {
    handleError(error, true)
  }
}

export const exportOrderMonthly = async ({ month, year }: { month: string; year: string }) => {
  const url = `${ADMIN_API_ROOT}/export/monthly?month=${month}&year=${year}`

  try {
    // @ts-ignore
    const data: AxiosResponse = await axios.get(url, getAxiosBlobConfig())
    return data
  } catch (error) {
    handleError(error, true)
  }
}

export const exportOrdersByDateRange = async ({ minDate, maxDate }: { minDate: string; maxDate: string }) => {
  const url = `${ADMIN_API_ROOT}/export/range?minDate=${minDate}&maxDate=${maxDate}`

  try {
    // @ts-ignore
    const data: AxiosResponse = await axios.get(url, getAxiosBlobConfig())
    return data
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Generate certificate EUR1
 * @param certificateId
 */
export const generateOrderEur1 = createAsyncThunk('admin/generateOrderEur1', async (certificateId: string) => {
  const url = `${ADMIN_API_URL.ORDER.ROOT}/${certificateId}/generate-eur1`

  try {
    const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, null, getAxiosConfig())
    return {
      order: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const updateBuyerByOrderId = createAsyncThunk(
  'admin/updateBuyerByOrderId',
  async ({ orderId, options }: { orderId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}/buyer`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.patch(url, options, getAxiosConfig())
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const setBuyer = createAsyncThunk(
  'admin/setBuyer',
  async ({ orderId, buyerId }: { orderId: string; buyerId: string }) => {
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}/buyer`

    try {
      const { data }: AxiosResponse<{ data: OrderType }> = await axios.put(url, { buyerId }, getAxiosConfig())
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
