import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { UserResponse, UserResponseList, UserTypes } from 'Types/user.types.ts'
import {
  AdminPaymentResponseType,
  CustomersParamsPayloadProps,
  CustomFilesResponse,
  CustomFileType,
  PaymentType,
} from 'Types/admin/admin'
import { UserApiType } from 'Schemas/user.types.ts'
import { UserType } from 'Definitions/User.type.ts'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken, getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get customer list
 */
export const getCustomerList = createAsyncThunk(
  'admin/getCustomerList',
  async ({ onlyForwarders = false }: { onlyForwarders: boolean }) => {
    let url = `${ADMIN_API_URL.CUSTOMER.ROOT}`
    const queryParams = []

    if (onlyForwarders) {
      queryParams.push(`onlyForwarders=${encodeURIComponent(onlyForwarders)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<UserResponseList> = await axios.get(url, config)
      return {
        customerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCustomerListIncludingExternal = createAsyncThunk(
  'admin/getCustomerListIncludingExternal',
  async ({ param, sort = 'CREATED_AT', order = 'DESC', pageIndex, pageSize }: CustomersParamsPayloadProps) => {
    let url = `${ADMIN_API_URL.CUSTOMER.ROOT}/search/include-external`
    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const config = getAxiosConfig()

    try {
      const { data }: AxiosResponse<UserResponseList> = await axios.get(url, config)
      return {
        customerListIncludingExternal: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getExternalCustomerList = createAsyncThunk(
  'admin/getExternalCustomerList',
  async ({ param, sort = 'CREATED_AT', order = 'DESC', pageIndex, pageSize }: CustomersParamsPayloadProps) => {
    let url = `${ADMIN_API_URL.EXTERNAL_USER.ROOT}`
    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const config = getAxiosConfig()

    try {
      const { data }: AxiosResponse<UserResponseList> = await axios.get(url, config)
      return {
        externalCustomerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchAllCustomerList = async ({
  param,
  sort = 'CREATED_AT',
  order = 'DESC',
  pageIndex,
  pageSize,
}: CustomersParamsPayloadProps) => {
  let url = `${ADMIN_API_URL.CUSTOMER.ROOT}/search/include-external`
  const queryParams = []

  if (param || param === '') {
    queryParams.push(`param=${encodeURIComponent(param)}`)
  }

  if (sort) {
    queryParams.push(`sort=${encodeURIComponent(sort)}`)
  }

  if (order) {
    queryParams.push(`order=${encodeURIComponent(order)}`)
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join('&')
    url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
  }

  const config = getAxiosConfig()

  try {
    const { data }: AxiosResponse<{ data: UserType[]; total: number }> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

export const updateExternalCustomer = createAsyncThunk(
  'admin/updateExternalCustomer',
  async ({ userId, options }: { userId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.EXTERNAL_USER.ROOT}/${userId}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<UserResponse> = await axios.patch(url, options, config)
      return {
        externalCustomer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const addExternalCustomer = createAsyncThunk('admin/addExternalCustomer', async (options: FormData) => {
  const url = ADMIN_API_URL.EXTERNAL_USER.ROOT
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    const { data }: AxiosResponse<UserResponse> = await axios.post(url, options, config)
    return {
      externalCustomer: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Upload customer's document
 * options: FormDate ("businessRegistry", "passportOrIdCard", "drivingLicence")
 */
export const uploadCustomerDocument = createAsyncThunk(
  'admin/uploadCustomerDocument',
  async ({ userId, options }: { userId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}/documents`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<{ data: UserType }> = await axios.patch(url, options, config)
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Block/Unblock the customer
 */
export const toggleBlockingCustomer = createAsyncThunk(
  'admin/toggleBlockingCustomer',
  async ({ userId, type }: { userId: string; type: 'block' | 'unblock' }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}/${type}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<UserResponse> = await axios.patch(url, null, config)
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Edit customer data
 *  - options: FormDate
 *  {
 *    firstName (string),
 *    lastName (string),
 *    country (string),
 *    city (string),
 *    streetName (string),
 *    streetNumber (string),
 *    postalCode (string),
 *    phone (string),
 *    companyName (string),
 *    drivingLicenceExpireAt (Date),
 *    passportOrIdCardExpireAt (Date),
 *    businessRegistryConfirm (true),
 *    businessRegistry (File),
 *    passportOrIdCard (File),
 *    drivingLicence (File)
 *  }
 */
export const updateCustomer = createAsyncThunk(
  'admin/updateCustomerData',
  async ({ userId, options }: { userId: string; options: UserTypes }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<{ data: UserType }> = await axios.patch(url, options, config)
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Search customers by params
 */
export const searchCustomers = createAsyncThunk(
  'admin/searchCustomers',
  async ({ param, sort = 'CREATED_AT', order = 'DESC', pageIndex, pageSize, type }: CustomersParamsPayloadProps) => {
    let url = `${ADMIN_API_URL.CUSTOMER.ROOT}/search`
    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (type) {
      queryParams.push(`type=${encodeURIComponent(type)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<UserResponseList> = await axios.get(url, config)
      return {
        customerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchForwarders = createAsyncThunk(
  'admin/searchForwarders',
  async ({ pageIndex, pageSize, param }: CustomersParamsPayloadProps) => {
    let url = `${ADMIN_API_URL.CUSTOMER.ROOT}/search`
    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&onlyForwarders=true&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: UserApiType[]; total: number }> = await axios.get(url, config)
      return {
        customerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove customer
 */
export const removeCustomer = createAsyncThunk('admin/removeCustomer', async (userId: string) => {
  const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<UserResponse> = await axios.delete(url, config)
    return {
      customerId: userId,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Get customer's custom files
 */
export const getCustomerCustomFiles = createAsyncThunk('admin/getCustomerCustomFiles', async (userId: string) => {
  const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}/custom-file`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomFilesResponse> = await axios.get(url, config)
    return {
      customerList: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Upload customer's custom file
 *
 * options: {
 *   userId: string
 *   options: {
 *     name: string
 *     customFile: File
 *   }
 * }
 */
export const uploadCustomerCustomFile = createAsyncThunk(
  'admin/uploadCustomerCustomFile',
  async ({ userId, options }: { userId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}/custom-file`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<{ data: CustomFileType }> = await axios.post(url, options, config)
      return {
        customFile: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateCustomerPaymentType = createAsyncThunk(
  'admin/updateCustomerPaymentType',
  async ({ userId, options }: { userId: string; options: { paymentType: PaymentType } }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${userId}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<UserResponse> = await axios.patch(url, options, config)
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

type PaymentQueryProps = {
  userId: string
  param?: string
  sort?: 'CREATED_AT' | 'PAID'
  order?: 'ASC' | 'DESC'
  minDate?: string
  maxDate?: string
}

/**
 * Payment history list
 */
export const getPaymentHistoryList = createAsyncThunk(
  'admin/getPaymentHistory',
  async ({ userId, param, sort = 'CREATED_AT', order = 'DESC', minDate, maxDate }: PaymentQueryProps) => {
    let url = ADMIN_API_URL.FACTURE.SEARCH
    const queryParams = []

    if (userId) {
      queryParams.push(`userId=${encodeURIComponent(userId)}`)
    }

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (minDate) {
      queryParams.push(`minDate=${encodeURIComponent(maxDate)}`)
    }

    if (maxDate) {
      queryParams.push(`maxDate=${encodeURIComponent(maxDate)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPaymentResponseType> = await axios.get(url, config)
      return {
        paymentHistory: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateCustomerStatus = createAsyncThunk(
  'admin/updateCustomerStatus',
  async ({ customerId, status }: { customerId: string; status: 'approve' | 'decline' }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${customerId}/${status}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: UserTypes }> = await axios.patch(url, null, config)
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateCustomerType = createAsyncThunk(
  'admin/updateCustomerType',
  async ({ customerId, type }: { customerId: string; type: 'FORWARDER' | 'LEGAL' | 'PHYSICAL' }) => {
    const url = `${ADMIN_API_URL.CUSTOMER.ROOT}/${customerId}/type`

    try {
      const { data }: AxiosResponse<{ data: UserTypes }> = await axios.patch(url, { type }, getAxiosConfig())
      return {
        customer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
