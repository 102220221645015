import { createSlice } from '@reduxjs/toolkit'

import { getCarValueByMonth, getCarValueByYear } from 'Api/admin/admin.statistic.service.ts'

type ValueProps = {
  totalValueFrance: number
  totalValueOutOfFrance: number
}

interface InitialStateProps {
  carValueByMonth: ValueProps
  carValueByYear: ValueProps
  month: number
  year: number
}

const currentTime = new Date()
const month = currentTime?.getMonth() + 1
const year = currentTime?.getFullYear()

const initialState: InitialStateProps = {
  carValueByMonth: {
    totalValueFrance: 0,
    totalValueOutOfFrance: 0,
  },
  carValueByYear: {
    totalValueFrance: 0,
    totalValueOutOfFrance: 0,
  },
  month,
  year,
}

export const carValueStatisticSlice = createSlice({
  name: 'carValueStatisticSlice',
  initialState,
  reducers: {
    setYear: (state, action) => {
      state.year = action.payload
    },
    setMonth: (state, action) => {
      state.month = action.payload
    },
    resetStatistic: (state) => {
      state.carValueByMonth = {
        totalValueFrance: 0,
        totalValueOutOfFrance: 0,
      }
      state.carValueByYear = {
        totalValueFrance: 0,
        totalValueOutOfFrance: 0,
      }
      state.month = month
      state.year = year
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarValueByMonth.fulfilled, (state, action) => {
        if (action.payload) {
          state.carValueByMonth = {
            totalValueFrance: action.payload.totalValueFrance,
            totalValueOutOfFrance: action.payload.totalValueOutOfFrance
          }
        }
      })
      .addCase(getCarValueByYear.fulfilled, (state, action) => {
        if (action.payload) {
          state.carValueByYear = {
            totalValueFrance: action.payload.totalValueFrance,
            totalValueOutOfFrance: action.payload.totalValueOutOfFrance
          }
        }
      })
  },
})

export const { setYear, setMonth, resetStatistic } = carValueStatisticSlice.actions
export default carValueStatisticSlice.reducer
