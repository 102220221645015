import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CONTACT_BASE_API_URL, USER_API, USER_BASE_API_URL } from '../constants/Global.constants.ts'

import { UserResponse, UserTypes } from '../types/user.types.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { FormikValues } from 'formik'
import { handleError } from 'Helpers/errors.helper.ts'

export const getUser = createAsyncThunk<UserTypes, void>('user/fetchUser', async () => {
  const url = USER_API.PROFILE_URL
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<UserResponse> = await axios.get(url, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const updateUser = createAsyncThunk('user/updateUser', async (options: FormikValues) => {
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    const { data }: AxiosResponse<UserResponse> = await axios.patch(USER_BASE_API_URL, options, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const updateUserPassword = async (options: { password: string }) => {
  const token = getAccessToken()
  const url = USER_API.UPDATE_USER_PASSWORD
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: { updated: boolean } }> = await axios.patch(url, options, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
}

export const finishOnboarding = createAsyncThunk('user/finishOnboarding', async (options: FormData) => {
  const url = USER_API.ONBOARDING_URL
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    const { data }: AxiosResponse<UserResponse> = await axios.post(url, options, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const setUserPrimaryShippingAddress = createAsyncThunk(
  'user/setShippingAddress',
  async (shippingAddressId: string) => {
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const options = {
      shippingAddressId,
    }

    try {
      const { data }: AxiosResponse<{ data: boolean }> = await axios.patch(
        USER_API.SET_PRIMARY_SHIPPING_ADDRESS,
        options,
        config
      )

      return { user: data.data, shippingAddressId }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.message)
      } else {
        throw new Error('An unexpected error occurred')
      }
    }
  }
)

interface ContactProps {
  name: string
  email: string
  phoneNumber: string
  subject: string
  comment: string
}

export const sendContactMail = async (options: ContactProps) => {
  try {
    const { data }: AxiosResponse<{ data: { sent: boolean } }> = await axios.post(CONTACT_BASE_API_URL, options)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
}
