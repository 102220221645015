import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FormikValues } from 'formik'

import { BUYER, BUYER_API, MIN_REQUIRED_SEARCH_CHARACTERS } from 'Constants/Global.constants.ts'
import { BuyerRequestOptions, BuyerResponse, BuyerType, PaginationProps } from 'Types/global.types.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get buyer list
 */
export const getBuyer = createAsyncThunk('buyer/get', async ({ pageIndex, pageSize }: PaginationProps) => {
  const token = getAccessToken()
  const url = `${BUYER}?page=${pageIndex}&limit=${pageSize}`

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<BuyerResponse> = await axios.get(url, config)
    return {
      buyers: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const loadBuyerList = async ({
  pageIndex,
  pageSize,
  param,
}: {
  pageIndex: number
  pageSize: number
  param?: string
}) => {
  const token = getAccessToken()
  let url = BUYER

  const queryParams = []

  if (param || param === '') {
    queryParams.push(`param=${encodeURIComponent(param)}`)
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join('&')
    url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
  }

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: BuyerType[]; total: number }> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Add a new buyer
 */
export const addBuyer = createAsyncThunk('buyer/add', async (options: FormikValues) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<BuyerResponse> = await axios.post(BUYER, options, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Update existing buyer
 */
export const updateBuyer = createAsyncThunk('buyer/update', async (payload: BuyerRequestOptions) => {
  const { id, options } = payload
  const token = getAccessToken()

  const url = `${BUYER}/${id}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<BuyerResponse> = await axios.patch(url, options, config)
    return {
      id,
      ...data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Remove buyer
 */
export const removeBuyer = createAsyncThunk('buyer/remove', async (id: string) => {
  const url = `${BUYER}/${id}`
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<BuyerResponse> = await axios.delete(url, config)
    return {
      id,
      ...data.data,
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

/**
 * Search buyer by keyword
 */
export const searchBuyer = createAsyncThunk('buyer/search', async (keyword: string) => {
  const url = `${BUYER_API.BUYER_SEARCH}?param=${keyword}`
  const token = getAccessToken()
  const charLength = keyword.length

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    if (charLength >= MIN_REQUIRED_SEARCH_CHARACTERS) {
      const { data }: AxiosResponse<BuyerResponse> = await axios.get(url, config)
      return {
        buyers: data.data,
      }
    } else {
      return []
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})
