import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import i18n from 'i18next'

interface ErrorResponse {
  error?: {
    type?: string
    message?: string
  }
}

const REDIRECT_ERRORS: Record<string, string> = {
  ADMIN_DOES_NOT_EXIST: '/admin/logout',
}

const getErrorMessage = (errorType: string) => {
  switch (errorType) {
    case 'FACTURE_PAID':
      return i18n.t('translation:common.error_types.FACTURE_PAID')
    case 'SOMETHING_WENT_WRONG':
      return i18n.t('translation:common.error_types.SOMETHING_WENT_WRONG')
    case 'INVALID_DATA':
      return i18n.t('translation:common.error_types.INVALID_DATA')
    case 'EMAIL_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.EMAIL_ALREADY_EXISTS')
    case 'BUYER_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.BUYER_ALREADY_EXISTS')
    case 'SELLER_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.SELLER_ALREADY_EXISTS')
    case 'USERNAME_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.USERNAME_ALREADY_EXISTS')
    case 'USER_CUSTOM_FILE_NAME_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.USER_CUSTOM_FILE_NAME_ALREADY_EXISTS')
    case 'VIN_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.VIN_ALREADY_EXISTS')
    case 'PURCHASE_CUSTOM_FILE_NAME_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.PURCHASE_CUSTOM_FILE_NAME_ALREADY_EXISTS')
    case 'STORAGE_PLACE_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.STORAGE_PLACE_ALREADY_EXISTS')
    case 'CUSTOMS_CROSSING_ALREADY_EXISTS':
      return i18n.t('translation:common.error_types.CUSTOMS_CROSSING_ALREADY_EXISTS')
    case 'SHIPPING_ADDRESS_DOES_NOT_EXISTS':
      return i18n.t('translation:common.error_types.SHIPPING_ADDRESS_DOES_NOT_EXISTS')
    case 'USER_DOES_NOT_EXISTS':
      return i18n.t('translation:common.error_types.USER_DOES_NOT_EXISTS')
    case 'SELLER_DOES_NOT_EXISTS':
      return i18n.t('translation:common.error_types.SELLER_DOES_NOT_EXISTS')
    case 'BUYER_DOES_NOT_EXISTS':
      return i18n.t('translation:common.error_types.BUYER_DOES_NOT_EXISTS')
    case 'INVALID_VERIFICATION_TOKEN':
      return i18n.t('translation:common.error_types.INVALID_VERIFICATION_TOKEN')
    case 'NOT_FOUND':
      return i18n.t('translation:common.error_types.NOT_FOUND')
    case 'INVALID_EORI_NUMBER':
      return i18n.t('translation:common.error_types.INVALID_EORI_NUMBER')
    case 'PAYMENT_SERVICE_ISSUE':
      return i18n.t('translation:common.error_types.PAYMENT_SERVICE_ISSUE')
    case 'FORBIDDEN':
      return i18n.t('translation:common.error_types.FORBIDDEN')
    case 'INVALID_DATA_SENT':
      return i18n.t('translation:common.error_types.INVALID_DATA_SENT')
    case 'USER_ALREADY_VERIFIED':
      return i18n.t('translation:common.error_types.USER_ALREADY_VERIFIED')
    case 'CERTIFICATE_ALREADY_PAID':
      return i18n.t('translation:common.error_types.CERTIFICATE_ALREADY_PAID')
    case 'EUR1_NOT_AVAILABLE':
      return i18n.t('translation:common.error_types.EUR1_NOT_AVAILABLE')
    default:
      return ''
  }
}

/**
 * Handle errors from API requests
 * @param {unknown} error - The error object from the API request.
 * @param {boolean} [isNotify] - Whether to show a toast notification for the error.
 * @param {boolean} [isLoginDrawerOpen] - Whether the login drawer is open.
 * @throws {Error} Throws an error with a message based on the error type.
 */
export const handleError = (error: unknown, isNotify?: boolean, isLoginDrawerOpen?: boolean) => {
  if (!axios.isAxiosError(error)) {
    throw new Error(error instanceof Error ? error.message : 'Unknown error occurred')
  }

  if (error.response) {
    const responseData = (error.response?.data as ErrorResponse)?.error
    const errorType = responseData?.type ?? ''
    const errorMessage = responseData?.message ?? i18n.t(`translation:common.description.something_went_wrong`)

    if (errorType in REDIRECT_ERRORS && window.location.pathname !== REDIRECT_ERRORS[errorType]) {
      window.location.href = REDIRECT_ERRORS[errorType]
      return
    }

    if (errorType === 'USER_BLOCKED') {
      if (isLoginDrawerOpen) {
        if (isNotify) toast.error(errorMessage)
        throw (error as AxiosError<ErrorResponse>)?.response?.data?.error
      } else {
        window.location.href = '/logout'
      }
    } else if (errorType === 'USER_NOT_VERIFIED') {
      if (isNotify) toast.error(errorMessage)
      throw (error as AxiosError<ErrorResponse>)?.response?.data?.error
    } else {
      const translatedErrorMessage = getErrorMessage(errorType) || errorMessage
      if (isNotify) toast.error(translatedErrorMessage)
      if (error instanceof Error) {
        throw error
      } else {
        throw new Error(i18n.t(`translation:common.description.something_went_wrong`))
      }
    }
  } else if (error.request) {
    const networkErrorMessage = 'Network error, please try again.'
    if (isNotify) toast.error(networkErrorMessage)
    throw new Error(networkErrorMessage)
  } else {
    if (isNotify) toast.error(error?.message || i18n.t(`translation:common.description.something_went_wrong`))
    if (error instanceof Error) {
      throw error
    } else {
      throw new Error(i18n.t(`translation:common.description.something_went_wrong`))
    }
  }
}
