import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { StoragePlace, StorageResponseList, StorageResponse } from 'Types/global.types'

import { ADMIN_API_ROOT, ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

interface RequestPayload {
  pageIndex: number
  pageSize: number
}

/**
 * Get storage place list
 */
export const getStoragePlacesList = createAsyncThunk(
  'admin/getStoragePlacesList',
  async ({ pageIndex, pageSize }: RequestPayload) => {
    const url = `${ADMIN_API_URL.STORAGE_PLACE.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<StorageResponseList> = await axios.get(url, config)
      return {
        storagePlacesList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchStoragePlaces = async ({
  param,
  pageIndex,
  pageSize,
}: {
  param: string
  pageIndex: number
  pageSize: number
}) => {
  const url = `${ADMIN_API_URL.STORAGE_PLACE.ROOT}/search?param=${param}&page=${pageIndex}&limit=${pageSize}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<StorageResponseList> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Update storage place
 */
export const updateStoragePlace = createAsyncThunk(
  'admin/updateStoragePlace',
  async ({ id, options }: { id: number; options: StoragePlace }) => {
    const url = `${ADMIN_API_ROOT}/storage-place/${id}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<StorageResponse> = await axios.patch(url, options, config)
      return {
        storagePlace: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Add storage place
 */
export const addStoragePlace = createAsyncThunk(
  'admin/addStoragePlace',
  async ({ options }: { options: StoragePlace }) => {
    const url = `${ADMIN_API_ROOT}/storage-place`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<StorageResponse> = await axios.post(url, options, config)
      return {
        storagePlace: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove storage place
 */
export const removeStoragePlace = createAsyncThunk('admin/removeStoragePlace', async (id: number) => {
  const url = `${ADMIN_API_ROOT}/storage-place/${id}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<StorageResponseList> = await axios.delete(url, config)
    return {
      storagePlaceId: id,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
