import { createSlice } from '@reduxjs/toolkit'

import { ChartOption } from 'Types/admin/admin'

import { getDiagramDataset, getMonthlyIncome, getYearlyIncome } from 'Api/admin/admin.statistic.service.ts'

type InitialPortalStateProps = {
  monthlyIncome: ChartOption
  yearlyIncome: ChartOption
  diagram: ChartOption[]
  month: number
  year: number
}

const currentTime = new Date()
const month = currentTime?.getMonth() + 1
const year = currentTime?.getFullYear()

const initialState: InitialPortalStateProps = {
  monthlyIncome: null,
  yearlyIncome: null,
  diagram: [],
  month,
  year,
}

export const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState,
  reducers: {
    setYear: (state, action) => {
      state.year = action.payload
    },
    setMonth: (state, action) => {
      state.month = action.payload
    },
    resetStatistic: (state) => {
      state.monthlyIncome = null
      state.yearlyIncome = null
      state.diagram = []
      state.month = month
      state.year = year
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlyIncome.fulfilled, (state, action) => {
        state.monthlyIncome = action.payload.income
      })
      .addCase(getYearlyIncome.fulfilled, (state, action) => {
        state.yearlyIncome = action.payload.income
      })
      .addCase(getDiagramDataset.fulfilled, (state, action) => {
        state.diagram = action.payload.diagram
      })
  },
})

export const { setYear, setMonth, resetStatistic } = statisticSlice.actions
export default statisticSlice.reducer
