import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { ChartOption, DashboardDataType, DiagramOptions } from 'Types/admin/admin'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get monthly income
 */
export const getMonthlyIncome = createAsyncThunk(
  'admin/getMonthlyIncome',
  async ({ month, year, type }: { month: number; year: number; type: DashboardDataType }) => {
    const url = `${ADMIN_API_URL.INCOME.ROOT}/monthly?month=${month}&year=${year}&type=${type}`

    try {
      const { data }: AxiosResponse<{ data: ChartOption }> = await axios.get(url, getAxiosConfig())
      return {
        income: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get yearly income
 */
export const getYearlyIncome = createAsyncThunk(
  'admin/getYearlyIncome',
  async ({ year, type }: { year: number; type: DashboardDataType }) => {
    const url = `${ADMIN_API_URL.INCOME.ROOT}/yearly?year=${year}&type=${type}`

    try {
      const { data }: AxiosResponse<{ data: ChartOption }> = await axios.get(url, getAxiosConfig())
      return {
        income: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getDiagramDataset = createAsyncThunk(
  'admin/getDiagramDataset',
  async ({ type, dataType, year, month }: DiagramOptions) => {
    const url = `${ADMIN_API_URL.INCOME.ROOT}/diagram?type=${type}&dataType=${dataType}&year=${year}&month=${month}`

    try {
      const { data }: AxiosResponse<{ data: { data: ChartOption[] } }> = await axios.get(url, getAxiosConfig())
      return {
        diagram: data.data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCarValueByMonth = createAsyncThunk(
  'admin/getCarValueByMonth',
  async ({ year, month }: { year: number; month: number }) => {
    const url = `${ADMIN_API_URL.INCOME_STATISTICS.ROOT}/monthly?year=${year}&month=${month}`

    try {
      const { data }: AxiosResponse<{ data: { totalValueFrance: number; totalValueOutOfFrance: number } }> =
        await axios.get(url, getAxiosConfig())
      return {
        ...data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCarValueByYear = createAsyncThunk('admin/getCarValueByYear', async ({ year }: { year: number }) => {
  const url = `${ADMIN_API_URL.INCOME_STATISTICS.ROOT}/yearly?year=${year}`

  try {
    const { data }: AxiosResponse<{ data: { totalValueFrance: number; totalValueOutOfFrance: number } }> =
      await axios.get(url, getAxiosConfig())
    return {
      ...data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
