import { createSlice } from '@reduxjs/toolkit'

import { CustomFileType } from 'Types/admin/admin'
import { BuyerApiType } from 'Schemas/buyer.types.ts'

import {
  getAdminBuyerCustomFiles,
  searchAdminBuyers,
  updateAdminBuyerByUserId,
  updateAdminBuyerStatus,
  updateAdminBuyerType,
  uploadAdminBuyersCustomFile,
} from 'Api/admin/admin.buyer.service.ts'

interface InitialStateProps {
  buyerList: BuyerApiType[]
  buyerCustomFiles: CustomFileType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  buyerList: [],
  buyerCustomFiles: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
}

const adminBuyerSlice = createSlice({
  name: 'adminBuyerSlice',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
    resetBuyerList: (state) => {
      state.buyerList = []
      state.buyerCustomFiles = []
      state.loading = false
      state.total = 0
      state.pageSize = 20
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAdminBuyers.fulfilled, (state, action) => {
        if (action.payload?.adminBuyerList) {
          state.buyerList = action.payload.adminBuyerList
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchAdminBuyers.pending, (state) => {
        state.loading = true
      })
      .addCase(searchAdminBuyers.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateAdminBuyerType.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyerList = state.buyerList.map((buyer) => {
            if (buyer.id === action.payload.buyer.id) {
              return action.payload.buyer
            }
            return buyer
          })
        }
      })
      .addCase(updateAdminBuyerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyerList = state.buyerList.map((buyer) => {
            if (buyer.id === action.payload.buyer.id) {
              return action.payload.buyer
            }
            return buyer
          })
        }
      })
      .addCase(updateAdminBuyerByUserId.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyerList = state.buyerList.map((buyer) => {
            if (buyer.id === action.payload.buyer.id) {
              return action.payload.buyer
            }
            return buyer
          })
        }
      })
      .addCase(getAdminBuyerCustomFiles.fulfilled, (state, action) => {
        if (action.payload?.customFiles) {
          state.buyerCustomFiles = action.payload.customFiles
        }
      })
      .addCase(uploadAdminBuyersCustomFile.fulfilled, (state, action) => {
        if (action.payload?.customFile) {
          state.buyerCustomFiles = [action.payload.customFile, ...state.buyerCustomFiles]
        }
      })
  },
})

const { actions } = adminBuyerSlice
export const { resetBuyerList, setParam } = actions
export default adminBuyerSlice.reducer
