import { createSlice } from '@reduxjs/toolkit'

import { SellerType } from 'Definitions/Seller.type.ts'

import {
  addAdminSeller,
  changeSellerStatus,
  getAdminSellers,
  updateAdminSeller,
  updateAdminSellerStatus,
} from 'Api/admin/admin.seller.service.ts'

interface InitialStateProps {
  adminSellers: SellerType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  adminSellers: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
}

const adminSellersSlice = createSlice({
  name: 'adminSellers',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminSellers.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = action.payload.adminSellers
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getAdminSellers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAdminSellers.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateAdminSeller.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
      .addCase(addAdminSeller.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers.unshift(action.payload.seller)
          state.total += 1
        }
      })
      .addCase(updateAdminSellerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
      .addCase(changeSellerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
  },
})

const { actions } = adminSellersSlice

export const { setParam } = actions

export default adminSellersSlice.reducer
