import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { BuyerApiType } from 'Schemas/buyer.types.ts'
import { CustomFileType } from 'Types/admin/admin'
import { BuyerType } from 'Definitions/Buyer.type.ts'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken, getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Update Admin buyer
 */
export const updateAdminBuyer = createAsyncThunk(
  'admin/updateAdminBuyer',
  async (payload: { id: string; options: FormData }) => {
    const { id, options } = payload
    const token = getAccessToken()

    const url = `${ADMIN_API_URL.BUYER.ROOT}/${id}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: BuyerType }> = await axios.patch(url, options, config)
      return {
        id,
        buyer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateAdminBuyerByUserId = createAsyncThunk(
  'admin/updateAdminBuyerByUserId',
  async (payload: { id: string; options: FormData }) => {
    const { id, options } = payload
    const token = getAccessToken()

    const url = `${ADMIN_API_URL.BUYER.ROOT}/${id}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: BuyerApiType }> = await axios.patch(url, options, config)
      return {
        id,
        buyer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchAdminBuyers = createAsyncThunk(
  'admin/searchAdminBuyers',
  async ({
    pageIndex,
    pageSize,
    userId,
    param = '',
  }: {
    pageIndex: number
    pageSize: number
    userId: string
    param: string
  }) => {
    const url = `${ADMIN_API_URL.BUYER.ROOT}?userId=${userId}&page=${pageIndex}&limit=${pageSize}&param=${param}`

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: BuyerApiType[]; total: number }> = await axios.get(url, config)

      return {
        adminBuyerList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateAdminBuyerStatus = createAsyncThunk(
  'admin/updateAdminBuyerStatus',
  async ({ buyerId, status }: { buyerId: string; status: 'approve' | 'decline' }) => {
    const url = `${ADMIN_API_URL.BUYER.ROOT}/${buyerId}/${status}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: BuyerApiType }> = await axios.patch(url, null, config)
      return {
        buyer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateAdminBuyerType = createAsyncThunk(
  'admin/updateAdminBuyerType',
  async ({ buyerId, type }: { buyerId: string; type: 'LEGAL' | 'PHYSICAL' }) => {
    const url = `${ADMIN_API_URL.BUYER.ROOT}/${buyerId}/type?type=${type}`

    try {
      const { data }: AxiosResponse<{ data: BuyerApiType }> = await axios.patch(url, null, getAxiosConfig())
      return {
        buyer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getAdminBuyerCustomFiles = createAsyncThunk('admin/getBuyerCustomFiles', async (buyerId: string) => {
  const url = `${ADMIN_API_URL.BUYER.ROOT}/${buyerId}/custom-file`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: CustomFileType[] }> = await axios.get(url, config)
    return {
      customFiles: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const uploadAdminBuyersCustomFile = createAsyncThunk(
  'admin/uploadAdminBuyersCustomFile',
  async ({ buyerId, options }: { buyerId: string; options: FormData }) => {
    const url = `${ADMIN_API_URL.BUYER.ROOT}/${buyerId}/custom-file`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: CustomFileType }> = await axios.post(url, options, config)
      return {
        customFile: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
