import { createSlice } from '@reduxjs/toolkit'

import { DhlType } from 'Types/admin/admin'

import {
  getDhlList,
  orderAdminDhl,
  removeDhl,
  updateDhlPaymentState,
  updateDhlState,
} from 'Api/admin/admin.dhl.service.ts'
import { cancelFacture } from 'Api/admin/admin.invoices.service.ts'

interface InitialStateProps {
  dhlList: DhlType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
  sort: 'PAID' | 'CREATED_AT' | 'NOT_PAID'
}

const initialState: InitialStateProps = {
  dhlList: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
  sort: 'CREATED_AT',
}

const adminDhlSlice = createSlice({
  name: 'adminDhlSlice',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
    setSort: (state, action) => {
      state.sort = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDhlList.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList = action.payload.dhlList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getDhlList.pending, (state) => {
        state.loading = true
      })
      .addCase(getDhlList.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateDhlState.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList = state.dhlList.map((dhl) => {
            if (dhl.id === action.payload.dhl.id) {
              return action.payload.dhl
            } else {
              return dhl
            }
          })
        }
      })
      .addCase(updateDhlPaymentState.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList = state.dhlList.map((dhl) => {
            if (dhl.id === action.payload.dhl.id) {
              return action.payload.dhl
            } else {
              return dhl
            }
          })
        }
      })
      .addCase(orderAdminDhl.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList.unshift(action.payload.dhl)
        }
      })
      .addCase(cancelFacture.fulfilled, (state, action) => {
        if (action.payload && action.payload.entityType === 'DHL') {
          // @ts-ignore
          state.dhlList = state.dhlList.map((dhl) => {
            if (dhl.facture.id === action.payload.facture.id) {
              return {
                ...dhl,
                paymentState: 'CANCELED',
                facture: action.payload.facture,
              }
            }
            return dhl
          })
        }
      })
      .addCase(removeDhl.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList = state.dhlList.filter((dhl) => dhl.id !== action.payload.dhl.id)
        }
      })
  },
})

export const { setParam, setSort } = adminDhlSlice.actions
export default adminDhlSlice.reducer
