import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createCertificate, searchCertificates } from 'Api/certificate.service.ts'
import { CertificateType, PaginationProps } from 'Types/global.types.ts'

type CertificateSliceProps = {
  certificates: CertificateType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  param: string
}

const initialState = {
  certificates: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  param: '',
} as CertificateSliceProps

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    setCertificatePageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCertificates.fulfilled, (state, action) => {
        if (action.payload) {
          state.certificates = action.payload.certificates
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchCertificates.pending, (state) => {
        state.loading = true
      })
      .addCase(searchCertificates.rejected, (state) => {
        state.loading = false
      })
      .addCase(createCertificate.fulfilled, (state, action) => {
        if (action.payload) {
          state.certificates.unshift(action.payload)
          state.loading = false
          state.total += 1
          state.pageIndex = 0
        }
      })
      .addCase(createCertificate.pending, (state) => {
        state.loading = true
      })
      .addCase(createCertificate.rejected, (state) => {
        state.loading = false
      })
  },
})

const { actions } = certificatesSlice

export const { setCertificatePageIndex, setParam } = actions
export default certificatesSlice.reducer
