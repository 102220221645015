import { configureStore } from '@reduxjs/toolkit'

import drawerReducer from './drawer/drawer.slice.ts'
import userReducer from './user/user.slice.ts'
import notificationReducer from './notifications/notifications.slice.ts'
import shippingAddress from './shippingAddress/shippingAddress.slice.ts'
import buyerSlice from './buyer/buyer.slice.ts'
import sellerSlice from 'Stores/seller/seller.slice.ts'
import certificatesSlice from 'Stores/certificates/certificates.slice.ts'
import purchaseSlice from 'Stores/purchase/purchase.slice.ts'
import dhlSlice from 'Stores/dhl/dhl.slice.ts'
import adminListSlice from 'Stores/adminList/adminList.slice.ts'
import adminPurchasesSlice from 'Stores/adminPurchases/adminPurchases.slice.ts'
import adminCustomersSlice from 'Stores/adminCustomers/adminCustomers.slice.ts'
import adminOrdersSlice from 'Stores/adminOrders/adminOrders.slice.ts'
import adminStoragePlacesSlice from 'Stores/adminStoragePlaces/adminStoragePlaces.slice.ts'
import adminCustomsCrossingSlice from 'Stores/adminCustomsCrossing/adminCustomsCrossing.slice.ts'
import adminCustomsVariableSlice from 'Stores/adminCustomsVariable/adminCustomsVariable.slice.ts'
import adminSellersSlice from 'Stores/adminSellers/adminSellers.slice.ts'
import adminForwardersSlice from 'Stores/adminForwarders/adminForwarders.slice.ts'
import adminEur1CounterSlice from 'Stores/adminEur1Counter/adminEur1Counter.slice.ts'
import adminBlacklistSlice from 'Stores/adminBlacklist/adminBlacklist.slice.ts'
import portalSlice from 'Stores/portal/portal.slice.ts'
import statisticSlice from 'Stores/adminStatistic/adminStatistic.slice.ts'
import adminDhlSlice from 'Stores/adminDhl/adminDhl.slice.ts'
import languageSlice from 'Stores//language/languageSlice.ts'
import adminDashboardSlice from 'Stores/adminDashboard/adminDashboard.slice.ts'
import adminPricesSlice from 'Stores/adminPrices/adminPrices.slice.ts'
import auctionHouseSlice from 'Stores/auctionHouse/auctionHouse.slice.ts'
import adminAuctionHouseSlice from 'Stores/adminAuctionHouse/adminAuctionHouse.slice.ts'
import adminCustomsOfficersSlice from 'Stores/adminCustomsOfficers/adminCustomsOfficers.slice.ts'
import customsSellersSlice from 'Stores/customsSellers/customsSellers.slice.ts'
import customsPurchasesSlice from 'Stores/customsPurchases/customsPurchases.slice.ts'
import customsOrdersSlice from 'Stores/customsOrders/customsOrders.slice.ts'
import customsOfficerSlice from 'Stores/customsOfficer/customsOfficer.slice.ts'
import customsOrderDetailsSlice from 'Stores/customsOrderDetails/customsOrderDetails.slice.ts'
import customsPurchaseDetailsSlice from 'Stores/customsPurchaseDetails/customsPurchaseDetails.slice.ts'
import adminBuyerSlice from 'Stores/adminBuyer/adminBuyer.slice.ts'
import adminDeblocageSlice from 'Stores/adminDeblocage/adminDeblocage.slice.ts'
import adminReusableEur1ListSlice from 'Stores/adminReusableEur1List/adminReusableEur1List.slice.ts'
import adminCarValueStatisticSlice from 'Stores/adminCarValueStatistic/adminCarValueStatistic.slice.ts'

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    user: userReducer,
    notifications: notificationReducer,
    shippingAddress: shippingAddress,
    buyer: buyerSlice,
    seller: sellerSlice,
    certificatesSlice,
    purchaseSlice,
    dhlSlice,
    adminListSlice,
    adminPurchasesSlice,
    adminCustomersSlice,
    adminStoragePlacesSlice,
    adminOrdersSlice,
    adminCustomsCrossingSlice,
    adminCustomsVariableSlice,
    adminSellersSlice,
    adminForwardersSlice,
    adminEur1CounterSlice,
    adminBlacklistSlice,
    portalSlice,
    statisticSlice,
    adminDhlSlice,
    languageSlice,
    adminDashboardSlice,
    adminPricesSlice,
    auctionHouseSlice,
    adminAuctionHouseSlice,
    adminCustomsOfficersSlice,
    customsSellersSlice,
    customsPurchasesSlice,
    customsOrdersSlice,
    customsOfficerSlice,
    customsOrderDetailsSlice,
    customsPurchaseDetailsSlice,
    adminBuyerSlice,
    adminDeblocageSlice,
    adminReusableEur1ListSlice,
    adminCarValueStatisticSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
